import { useWebsiteContext } from "../../context/website"

const BookButton = ({ label, styles = {}, classes = "", singleLocation = false }) => {
    const { showBookModal, setShowBookModal } = useWebsiteContext();

    return (
        <>
            <button onClick={() => setShowBookModal({ ...showBookModal, show: true })} className={`focus:tw-outline-none tw-whitespace-nowrap tw-font-normal tw-p-0 ${classes}`} style={styles}>
                {label}
            </button>
        </>
    )
}

export default BookButton