import React from "react";
import "./previewloading.css";
import { FaHammer } from "react-icons/fa";

const BuilderToast = ({ category, message = null }) => {
  const categories = {
    "createdProject": {
      loading: false,
      message: "Project was created successfully."
    },
    "createProjectError": {
      loading: false,
      message: "Oops, we couldn't create your project."
    },
    "editedProject": {
      loading: false,
      message: "Project was edited successfully."
    },
    "editProjectError": {
      loading: false,
      message: "Oops, we couldn't edit your project."
    },
    "duplicatingProject": {
      loading: true,
      message: "Please wait while we finish duplicating your website."
    },
    "duplicatedProject": {
      loading: false,
      message: "Your project was duplicated successfully."
    },
    "duplicateProjectError": {
      loading: false,
      message: "Oops, we couldn't duplicate your website."
    },
    "domainAdded": {
      loading: false,
      message: "Domain was successfully added to this project."
    },
    "domainAddError": {
      loading: false,
      message: "Oops, we couldn't associate domain to this project."
    },
    "deletedDomain": {
      loading: false,
      message: "Domain is successfully deleted from this project"
    },
    "deleteDomainError": {
      loading: false,
      message: "Oops, we couldn't delete domain from this project."
    },
    "replacedDNS": {
      loading: false,
      message: "Previous DNS record was deleted and new one was added to the project"
    },
    "replaceDNSError": {
      loading: false,
      message: "Oops, we couldn't replace the dns record of this project."
    },
    "publishStarted": {
      loading: true,
      message: "Wait some time while we finish publishing your website."
    },
    "publishError": {
      loading: false,
      message: "Oops, we couldn't publish your website."
    },
    "pageAdded": {
      loading: false,
      message: "The page has been added."
    },
    "savingPage": {
      loading: true,
      message: "Please wait while we save your page."
    },
    "pageSaved": {
      loading: false,
      message: "Your page has been saved succesfully."
    },
    "pageSaveError": {
      loading: false,
      message: "Oops, we couldn't save your page."
    },
    "layoutChanged": {
      loading: false,
      message: "The new layout has been applied."
    },
    "fieldChanged": {
      loading: false,
      message: "The field has been changed successfully."
    },
    "unsplashFetchError": {
      loading: false,
      message: "Oops, we couldn't fetch from unsplash."
    },
    "commonError": {
      loading: false,
      message: "Oops, Something went wrong!"
    }
  }

  const currentCategory = categories[category];

  return (
    <div className="tw-h-full">
      <div className="tw-h-full tw-w-full tw-flex tw-items-center tw-gap-3">
        {currentCategory.loading ?
          <div className="builder-sk-folding-cube">
            <div className="builder-sk-cube1 builder-sk-cube" />
            <div className="builder-sk-cube2 builder-sk-cube" />
            <div className="builder-sk-cube4 builder-sk-cube" />
            <div className="builder-sk-cube3 builder-sk-cube" />
          </div>
          :
          <div className="tw-p-2 tw-rounded-full tw-bg-background dark:tw-bg-backgroundDark">
            <FaHammer size={20} />
          </div>
        }
        <div className="tw-text-center">
          {message || currentCategory.message}
        </div>
      </div>
    </div >
  );
};

export default BuilderToast;
