import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { IoHome } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa";
import { useWebsiteContext } from "../../../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";
import { EditableLink } from "../../../utils/components";
import { getAbsolutePath } from "../../../../lib/utils";
import useEditingPath from "../../../../hooks/useEditingPath";
import { Link } from 'react-router-dom';

export type BreadcrumbProps = globalCommonFieldsType & {
    align?: string;
    links: {
        label: string;
        href: string;
        typography: textFieldsType;
    }[];
};

export const Breadcrumb: ComponentConfig<BreadcrumbProps> = {
    fields: {
        align: {
            type: "radio",
            options: [
                { label: "left", value: "left" },
                { label: "center", value: "center" },
            ],
            label: "Align"
        },
        links: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Link ${i + 1}`,
            arrayFields: {
                label: { type: "text", label: "Label" },
                href: { type: "text", label: "Href" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            defaultItemProps: {
                label: "link",
                href: "#",
                typography: createDefaultProps("text", {
                    fontSize: 15,
                    fontWeight: 500,
                    lineHeight: 1.2,
                }),
            },
            label: "Link"
        },
        ...globalCommonFields
    },
    defaultProps: {
        align: "center",
        links: [],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 64, right: 0, bottom: 64, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();
        const { isEdit } = useEditingPath();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type == "linkLabel") {
                updatedData.links[identifier].label = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Breadcrumb_theme_6_variant_1" }
        };

        const theme = websiteTheme.name === "theme_6" ? websiteTheme.content : themes.theme_6
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper className="" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <nav style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`tw-flex tw-w-full`} aria-label="Breadcrumb">
                    <ol className={`tw-flex tw-items-center tw-space-x-3 ${data.align === "center" ? "tw-mx-auto" : ""}`}>
                        <li>
                            <div className="tw-flex">
                                <IoHome className="tw-h-5 tw-w-5" aria-hidden="true" />
                                <Link to={getAbsolutePath("home", isEdit)} className="tw-ml-2 hover:tw-text-secondary">Home</Link>
                            </div>
                        </li>
                        {data.links.map((link, i) => (
                            <li key={i}>
                                <div className="tw-flex tw-items-center">
                                    <FaChevronRight className="tw-h-4 tw-w-4" aria-hidden="true" />
                                    <EditableLink classes="tw-ml-2" href={getAbsolutePath(link.href, isEdit)} handleChange={(e) => handleChange(e, "linkLabel", i)} data={{ typography: link.typography }}>
                                        {link.label}
                                    </EditableLink>
                                </div>
                            </li>
                        ))}
                    </ol>
                </nav>
            </Wrapper>

        );
    },
};