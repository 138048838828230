import { useLocation } from 'react-router-dom'

export const getBasePath = (pathname) => {
  const parts = pathname.split("/");
  return parts.slice(0, parts.length - 2).join("/");
};

export const isExternalPath = (relativePath) => {
  const urlPattern = /^(http:\/\/|https:\/\/)/;

  if (!urlPattern.test(relativePath)) {
    return false
  }

  return true;
}

export const isSpecialPath = (relativePath) => {
  const telPattern = /^tel:/;
  const emailPattern = /^mailto:/;

  if (!telPattern.test(relativePath) && !emailPattern.test(relativePath)) {
    return false
  }

  return true;
}

export const getAbsolutePath = (relativePath, isEdit = false) => {
  const location = useLocation();
  const basePath = getBasePath(location.pathname);

  if (isExternalPath(relativePath) && isSpecialPath(relativePath)) {
    return relativePath
  }

  if (process.env.REACT_APP_BUILDER_ENV === "production" || process.env.REACT_APP_BUILDER_ENV === "staging") {
    return relativePath
  }
  return isEdit ? `${basePath}/${relativePath}/edit` : `${basePath}/${relativePath}/render`;
};