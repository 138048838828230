import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableText, EditableLink } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { ImageField } from "../../../puck_fields";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";

export type HeroSectionProps = globalCommonFieldsType & {
    title: {
        text: string;
        typography: textFieldsType
    };
    description: {
        text: string;
        typography: textFieldsType
    };
    align?: string;
    phone: (globalCommonFieldsType & {
        text: string;
        typography: textFieldsType
    });
    image?: {
        mode?: "inline" | "background";
        url?: string;
    };
};

export const HeroSection: ComponentConfig<HeroSectionProps> = {
    fields: {
        title: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Title"
        },
        description: {
            type: "object",
            objectFields: {
                text: { type: "textarea", label: "Text" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            label: "Description"
        },
        phone: {
            type: "object",
            objectFields: {
                text: { type: "text", label: "Number" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
                ...globalCommonFields
            },
            label: "Phone"
        },
        align: {
            type: "radio",
            options: [
                { label: "left", value: "left" },
                { label: "center", value: "center" },
            ],
            label: "Align"
        },
        image: {
            type: "object",
            objectFields: {
                url: { ...ImageField, label: "Url" },
                mode: {
                    type: "radio",
                    options: [
                        { label: "inline", value: "inline" },
                        { label: "background", value: "background" },
                    ],
                    label: "Mode"
                },
            },
            label: "Image"
        },
        ...globalCommonFields
    },
    defaultProps: {
        title: {
            text: "For that feminine look you have always wanted.",
            typography: createDefaultProps("text", {
                font: { name: "Roboto" },
                fontSize: 35,
                fontWeight: 600,
                lineHeight: 1.3,
            }),
        },
        description: {
            text: "For that feminine look you have always wanted to have with you.",
            typography: createDefaultProps("text", {
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
                font: { name: "Montserrat" }
            }),
        },
        align: "left",
        phone: {
            text: "(910) 491-1926",
            typography: createDefaultProps("text"),
            ...createDefaultProps("globalCommon", {
                spacing: { padding: { left: 16, top: 12, right: 16, bottom: 12, } },
                backgroundColor: { hex: themes.theme_1.colors.secondary }
            })
        },
        image: {
            url: "https://images.fresha.com/locations/location-profile-images/946641/1208694/15afd873-4648-4026-80cb-8aaf7d3e5d96.jpg?class=venue-gallery-large",
            mode: "inline"
        },
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 64, right: 0, bottom: 64, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();

        const handleChange = (e, type) => {
            const updatedData = { ...data };
            if (type === 'title') {
                updatedData.title.text = e.target.textContent;
            } else if (type === 'description') {
                updatedData.description.text = e.target.textContent;
            } else if (type === 'phone') {
                updatedData.phone.text = e.target.textContent;
            }

            return { updatedData: updatedData, type: "HeroSection_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.background : data.backgroundColor.hex

        return (
            <Wrapper className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`${data.align === "center" ? "" : "lg:tw-grid-cols-2 tw-grid tw-justify-center tw-items-center"} tw-gap-10 lg:tw-gap-3`}>
                    <div className={`tw-flex tw-flex-col tw-z-10 tw-gap-4 tw-text-center ${data.align === "center" ? "" : "lg:tw-text-left"} ${data.align !== "center" && data.image?.mode !== "background" ? "lg:tw-py-[60px]" : ""}`}>
                        <DropZone zone="hero_theme_1_variant_1_1" allow={["Text", "Image", "Link", "Video"]} />

                        <EditableText classes="tw-font-medium tw-text-center md:tw-text-left" handleChange={(e) => handleChange(e, "title")} data={data.title.typography}>
                            {data.title.text}
                        </EditableText>
                        <EditableText classes="" handleChange={(e) => handleChange(e, "description")} data={data.description.typography}>
                            {data.description.text}
                        </EditableText>

                        <div className={`${data.align === "center" ? "tw-justify-center" : "tw-justify-center lg:tw-justify-start"} tw-flex tw-mt-5  tw-w-full`}>
                            <EditableLink styles={{ padding: generateSpacingCSS(data.phone.spacing.padding), margin: generateSpacingCSS(data.phone.spacing.margin), backgroundColor: data.phone.backgroundColor.hex, ...generateBorderCSS(data.phone.border) }} classes="tw-inline-flex tw-justify-center tw-items-center" href={`tel:${data.phone.text}`} handleChange={(e) => handleChange(e, "phone")} data={{ typography: data.phone.typography }}>
                                {data.phone.text}
                            </EditableLink>
                        </div>

                        <DropZone zone="hero_theme_1_variant_1_2" allow={["Text", "Image", "Link", "Video"]} />
                    </div>

                    {data.image?.mode === "background" && (
                        <div className="tw-h-[220px] sm:tw-h-[300px] md:tw-h-[330px] lg:tw-h-[356px] xl:tw-h-[420px]">
                            <div
                                style={{
                                    backgroundImage: `url("${data.image?.url}")`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    left: 0
                                }}
                            ></div>

                            <div style={{
                                backgroundImage: `linear-gradient(-90deg, rgba(249, 250, 255, 0.6) 0%, rgba(249, 250, 255, 0.1) 80%)`,
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                left: 0,
                            }}></div>
                        </div>
                    )}

                    {data.align !== "center" && data.image?.mode !== "background" && data.image?.url && (
                        <div
                            className="tw-hidden lg:tw-block"
                            style={{
                                backgroundImage: `url("${data.image?.url}")`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                position: "absolute",
                                right: 0,
                                top: 0,
                                bottom: 0,
                                left: "45%"
                            }}
                        ></div>
                    )}

                </div>
            </Wrapper>
        );
    },
};
