import React, { useState } from "react";
import { ComponentConfig, DropZone, usePuck } from "@measured/puck";
import Wrapper from "../../../utils/Wrapper";
import { EditableLink } from "../../../utils/components";
import { useWebsiteContext } from "../../../../context/website";
import { type globalCommonFieldsType, createDefaultProps, globalCommonFields, type textFieldsType, textFields } from "../../../utils/CommonFields";
import { generateSpacingCSS, generateBorderCSS, GetCurrentFont } from '../../../helpers'
import { themes } from "../../../../theme";
import { getAbsolutePath } from "../../../../lib/utils";
import useEditingPath from "../../../../hooks/useEditingPath";
import { Link } from 'react-router-dom';

export type BreadcrumbProps = globalCommonFieldsType & {
    links: {
        label: string;
        href: string;
        typography: textFieldsType;
    }[];
};

export const Breadcrumb: ComponentConfig<BreadcrumbProps> = {
    fields: {
        links: {
            type: "array",
            getItemSummary: (item, i) => item.label || `Link ${i + 1}`,
            arrayFields: {
                label: { type: "text", label: "Label" },
                href: { type: "text", label: "Href" },
                typography: {
                    type: "object",
                    objectFields: textFields,
                    label: "Typography"
                },
            },
            defaultItemProps: {
                label: "link",
                href: "#",
                typography: createDefaultProps("text", {
                    fontSize: 15,
                    fontWeight: 500,
                    lineHeight: 1.2,
                }),
            },
            label: "Link"
        },
        ...globalCommonFields
    },
    defaultProps: {
        links: [],
        ...createDefaultProps("globalCommon", { spacing: { padding: { left: 0, top: 75, right: 0, bottom: 75, } } })
    },

    render: (data) => {
        const { websiteTheme } = useWebsiteContext();
        const { isEdit } = useEditingPath();

        const handleChange = (e, type, identifier = null) => {
            const updatedData = { ...data };
            if (type == "linkLabel") {
                updatedData.links[identifier].label = e.target.textContent;
            }

            return { updatedData: updatedData, type: "Breadcrumb_theme_1_variant_1" }
        };

        const theme = websiteTheme.name === "theme_1" ? websiteTheme.content : themes.theme_1
        const backgroundColor = data.backgroundColor.hex === "Default" ? theme.colors.primary : data.backgroundColor.hex

        return (
            <Wrapper isCenteredLayout={false} className="tw-relative" styles={{ backgroundColor: backgroundColor, ...generateBorderCSS(data.border), color: theme.colors.text }}>
                <div className={`tw-mx-auto tw-max-w-screen-md`}>
                    <nav style={{ padding: generateSpacingCSS(data.spacing.padding), margin: generateSpacingCSS(data.spacing.margin) }} className={`tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-w-full tw-text-center`} aria-label="Breadcrumb">
                        <h1>{data.links.length > 0 ? data.links[data.links.length - 1].label : "Home"}</h1>
                        <ol className={`tw-flex tw-items-center tw-space-x-1 tw-mx-auto tw-font-['Roboto']`}>
                            <li>
                                <div className="tw-flex">
                                    <Link to={getAbsolutePath("home", isEdit)} className="tw-ml-2 hover:tw-text-secondary">Home</Link>
                                </div>
                            </li>
                            {data.links.length > 0 && data.links.map((link, i) => (
                                <li key={i}>
                                    <div className="tw-flex tw-items-center">
                                        <span>/</span>
                                        <EditableLink classes="tw-ml-1" href={getAbsolutePath(link.href, isEdit)} handleChange={(e) => handleChange(e, "linkLabel", i)} data={{ typography: link.typography }}>
                                            {link.label}
                                        </EditableLink>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </nav>
                </div>
            </Wrapper>

        );
    },
};