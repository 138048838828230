import axios from "axios";
import { callApiDam } from "./caller";

// for development
const apiKey = process.env.REACT_APP_DAM_SERVER_APIKEY

export const getProjects = async (businessId, networkId) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${networkId}/projects` : `website-builder/:BID:/${networkId}/projects`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const getProject = async (businessId, projectId) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/project/${projectId}` : `website-builder/:BID:/project/${projectId}`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const deleteProject = async (businessId, projectId) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/project/${projectId}/delete` : `website-builder/:BID:/project/${projectId}/delete`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const duplicateProject = async (businessId, projectId, user) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${user.id}/project/${projectId}/duplicate` : `website-builder/:BID:/${user.id}/project/${projectId}/duplicate`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        {
            author: user.author
        }
    );
    return result;
};

export const createProject = async (businessId, networkId, userId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${networkId}/${userId}/project` : `website-builder/:BID:/${networkId}/${userId}/project`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const updateProject = async (businessId, networkId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${networkId}/project/edit` : `website-builder/:BID:/${networkId}/project/edit`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const getPages = async (businessId, projectId) => {
    if (process.env.REACT_APP_BUILDER_ENV === "production" || process.env.REACT_APP_BUILDER_ENV === "staging") {
        try {
            const result = await axios.get("/data/pages.json")
            return {
                data: result.data,
                success: true,
                message: "Successfully retrieved pages"
            };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e)
            return { succes: false, message: "Error fetching pages." };
        }
    } else {
        const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/project/${projectId}/pages` : `website-builder/:BID:/project/${projectId}/pages`;
        const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

        const result = await callApiDam(
            url,
            "GET"
        );
        return result;
    }
};

export const getPage = async (businessId, pageId) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/page/${pageId}` : `website-builder/:BID:/page/${pageId}`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const createPage = async (businessId, networkId, projectId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${networkId}/project/${projectId}/page` : `website-builder/:BID:/${networkId}/project/${projectId}/page`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const updatePages = async (businessId, networkId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${networkId}/pages/edit` : `website-builder/:BID:/${networkId}/pages/edit`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const deletePage = async (businessId, pageId) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/page/${pageId}/delete` : `website-builder/:BID:/page/${pageId}/delete`
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const publish = async (businessId, projectId, userId) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/${projectId}/publish` : `website-builder/:BID:/${projectId}/publish`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        {
            userId
        }
    );
    return result;
};

export const getUnsplashImages = async (businessId, page, perPage, query) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/unsplash-images?page=${page}&per_page=${perPage}&query=${query}` : encodeURIComponent(`website-builder/:BID:/unsplash-images?page=${page}&per_page=${perPage}&query=${query}`);
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "GET"
    );
    return result;
};

export const deleteAndAddDNSRecord = async (businessId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/delete-and-add-record` : `website-builder/:BID:/delete-and-add-record`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const domainInCloudflare = async (businessId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/domain-in-cloudflare` : `website-builder/:BID:/domain-in-cloudflare`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const domainInGivenAccount = async (businessId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/domain-in-given-account` : `website-builder/:BID:/domain-in-given-account`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};

export const deleteDomainAndRecord = async (businessId, data) => {
    const dest = process.env.REACT_APP_ENV === "development" ? `/${apiKey}/website-builder/${businessId}/delete-domain-and-record` : `website-builder/:BID:/delete-domain-and-record`;
    const url = process.env.REACT_APP_ENV === "development" ? dest : `${process.env.REACT_APP_MOBILE_APP_BACKEND_URL}/${businessId}/dam-server?dest=${dest}`;

    const result = await callApiDam(
        url,
        "POST",
        data
    );
    return result;
};