import { Link } from 'react-router-dom'
import React, { useState } from 'react';
import { Popper, ClickAwayListener, Grow, Paper, MenuItem, MenuList } from '@mui/material';
import { getAbsolutePath } from '../../../lib/utils';
import BookButton from '../../utils/BookButton';
import { generateSpacingCSS } from '../../helpers';
import Wrapper from '../../utils/Wrapper';
import { useWebsiteContext } from '../../../context/website';
import { ObjectComponent } from "../../config/overrides";

const Header = ({ editMode, theme, pages, assets, data }: { editMode: boolean, theme: any, pages: any, assets: any, data: any }) => {
    const { websiteData } = useWebsiteContext();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const anchorRef = React.useRef(null);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setIsMobileMenuOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setIsMobileMenuOpen(false);
        } else if (event.key === 'Escape') {
            setIsMobileMenuOpen(false);
        }
    }

    const headerStyle: React.CSSProperties = {
        backgroundColor: theme.colors.primary,
        color: theme.colors.text,
        fontFamily: `"${theme.fonts[0]}", serif`,
    };

    const NavItem = ({ label, href, editMode }) => {
        const navPath = window.location.pathname;
        const isActive = navPath == href;

        return (
            <Link
                to={href || "/"}
                style={{ color: isActive ? theme.colors.secondary : 'inherit' }}
                className={`tw-text-decoration-none tw-w-full ${isActive ? 'tw-font-semibold' : 'tw-font-normal'}`}
            >
                {label}
            </Link>
        );
    };

    return (
        <header style={headerStyle}>
            <nav ref={anchorRef} className="tw-py-5">
                <Wrapper noStyles>
                    <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-mx-auto tw-max-w-screen-xl">
                        {/* Logo and Branding */}
                        <Link to={getAbsolutePath("home", editMode)} className="tw-flex tw-items-center">
                        {(data?.logoType === "both" || data?.logoType === "image") && assets?.logo &&
                                <img src={assets?.logo} className="tw-mr-3 tw-h-[56px] sm:tw-h-[64px]" alt="Logo" />
                            }
                            {
                                (data?.logoType === "both" || data?.logoType === "text") &&
                                <span className="tw-self-center tw-text-xl tw-font-semibold tw-whitespace-nowrap">{websiteData.name}</span>
                            }
                        </Link>
                        <button className="lg:tw-hidden tw-group tw-cursor-pointer tw-origin-right lg:tw-scale-100 tw-h-[20px] tw-w-8 lg:tw-w-10 tw-my-auto tw-relative tw-flex tw-items-center tw-bg-transparent focus:tw-outline-none" onClick={toggleMobileMenu}>
                            <div
                                style={{ backgroundColor: theme.colors.secondary }}
                                className={`tw-transition tw-m-auto tw-origin-center tw-h-[4px] tw-w-full tw-rounded-xl tw-absolute ${isMobileMenuOpen
                                    ? 'tw-rotate-45 tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 '
                                    : 'tw-left-0 tw-top-0 '
                                    }`}
                            ></div>
                            <div
                                style={{ backgroundColor: theme.colors.secondary }}
                                className={`tw-transition tw-m-auto tw-origin-center tw-h-[4px] tw-rounded-xl tw-absolute ${isMobileMenuOpen
                                    ? 'tw-rotate-45 tw-w-full tw-left-0 tw-top-0 tw-bottom-0 tw-right-0'
                                    : 'tw-right-0 tw-top-3 tw-w-2/3 group-hover:tw-w-full tw-transition-all tw-duration-200 tw-ease-linear'
                                    }`}
                            ></div>
                            <div
                                style={{ backgroundColor: theme.colors.secondary }}
                                className={`tw-transition tw-m-auto tw-origin-center tw-h-[4px] tw-rounded-xl tw-absolute ${isMobileMenuOpen
                                    ? '-tw-rotate-45 tw-w-full tw-left-0 tw-top-0 tw-bottom-0 tw-right-0'
                                    : 'tw-left-0 tw-top-6 tw-w-full'
                                    }`}
                            ></div>
                        </button>

                        {/* for desktop and tablet */}
                        <div className={`tw-hidden tw-justify-between tw-items-center tw-w-full lg:tw-flex lg:tw-w-auto lg:tw-order-1`}>
                            <ul className="tw-flex tw-flex-col tw-items-center tw-mt-4 tw-font-medium lg:tw-flex-row lg:tw-space-x-8 lg:tw-mt-0">
                                {
                                    pages.map((page, i) =>
                                        <NavItem key={i} editMode={editMode} label={page.name} href={getAbsolutePath(page.link, editMode)} />
                                    )
                                }
                                <BookButton label="Book Appointment" styles={{ color: 'inherit', backgroundColor: "transparent", border: `2px solid ${theme.colors.text}`, fontFamily: "inherit", fontSize: "inherit", padding: generateSpacingCSS({ left: 8, top: 3, right: 8, bottom: 3 }) }} />
                            </ul>
                        </div>

                        {/* for mobile */}
                        <Popper
                            open={isMobileMenuOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-end"
                            transition
                            disablePortal
                            className='tw-w-[200px] tw-z-[100] tw-rounded-bl tw-overflow-hidden'
                            placeholder=""
                            onPointerEnterCapture={() => { }}
                            onPointerLeaveCapture={() => { }}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={closeMobileMenu}>
                                            <MenuList
                                                autoFocusItem={isMobileMenuOpen}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                                className='tw-w-full'
                                                style={{ backgroundColor: theme.colors.background, color: theme.colors.text }}
                                            >
                                                {
                                                    pages.map((page, i) =>
                                                        <MenuItem key={i} onClick={closeMobileMenu}>
                                                            <NavItem editMode={editMode} label={page.name} href={getAbsolutePath(page.link, editMode)} />
                                                        </MenuItem>
                                                    )
                                                }
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </Wrapper>
            </nav>
        </header>
    );
};

const HeaderEditor = ({ data, setData }) => {
    const config = {
        label: "Header",
        onChange: (val) => setData(val),
        value: data,
        field: {
            type: "object",
            objectFields: {
                logoType: {
                    type: "radio",
                    options: [
                        { label: "Image", value: "image" },
                        { label: "Text", value: "text" },
                        { label: "Both", value: "both" },
                    ],
                    label: "Logo Type"
                },
            }
        }
    }

    return <ObjectComponent {...config} />
}

export { Header, HeaderEditor };