import { usePuck } from "@measured/puck";
import { useState } from "react";
import useEditingPath from "../../hooks/useEditingPath";
import { Link } from 'react-router-dom';
import { isExternalPath, isSpecialPath } from "../../lib/utils";

export function EditableText({
    children,
    classes = "",
    handleChange,
    data,
    styles = {},
}) {
    const { appState, dispatch } = usePuck();
    const { isEdit } = useEditingPath();

    const [isEditable, setIsEditable] = useState(false);
    const [editableItemSelector, setEditableItemSelector] = useState(null);

    const actions = {
        makeEditable: () => {
            setIsEditable(true)
            setEditableItemSelector(appState.ui.itemSelector)
        },
        makeChanges: (e) => {
            e.preventDefault();
            const { updatedData, type } = handleChange(e);
            dispatch({
                type: "replace",
                destinationIndex: editableItemSelector.index,
                destinationZone: editableItemSelector.zone,
                data: { props: { ...updatedData }, type: type },
            });
        },
        makeNonEditable: () => {
            setIsEditable(false)
            setEditableItemSelector(null)
        }
    }

    const onBlur = (e) => {
        actions.makeChanges(e);
        actions.makeNonEditable();
    }

    const combinedStyles: React.CSSProperties = {
        fontFamily: data?.font?.name ? data.font.name : 'inherit',
        color: data?.color?.hex || 'inherit',
        textAlign: data.align || 'inherit',
        fontSize: `${data.fontSize || 16}px`,
        fontWeight: data.fontWeight || 300,
        lineHeight: data.lineHeight || 1,
        overflowWrap: 'break-word',
        fontStyle: data.style || "normal",
        textDecoration: data.decoration || "none",
        textTransform: data.transform || "none",
        letterSpacing: `${data.letterSpacing || 0.14}px`,
        ...styles,
    };

    return (
        <div
            suppressContentEditableWarning
            contentEditable={isEdit && isEditable}
            onDoubleClick={actions.makeEditable}
            onBlur={onBlur}
            style={combinedStyles}
            className={classes}
        >
            {children}
        </div>
    );
}

export function EditableButton({
    children,
    classes = "",
    handleChange,
    data,
    handleClick = () => { },
    styles = {},
    isSubmit = false
}) {
    const { isEdit } = useEditingPath();

    const combinedStyles: React.CSSProperties = {
        ...styles,
    };

    return (
        isEdit ? (
            <div
                style={combinedStyles}
                className={classes}
            >
                <EditableText classes="" handleChange={handleChange} data={data.typography}>
                    {children}
                </EditableText>
            </div>
        ) : (
            <button
                type={isSubmit ? "submit" : "button"}
                style={combinedStyles}
                onClick={handleClick}
                className={classes}
            >
                <EditableText classes="" handleChange={handleChange} data={data.typography}>
                    {children}
                </EditableText>
            </button>
        )
    );
}

export function EditableLink({
    children,
    classes = "",
    handleChange,
    data,
    href,
    styles = {},
}) {
    const { isEdit } = useEditingPath();

    const combinedStyles: React.CSSProperties = {
        ...styles,
    };

    const isExternal = isExternalPath(href);
    const isSpecial = isSpecialPath(href);

    return (
        isEdit ? (
            <div
                style={combinedStyles}
                className={classes}
            >
                <EditableText classes="" handleChange={handleChange} data={data.typography}>
                    {children}
                </EditableText>
            </div>
        ) : (
            isExternal || isSpecial ?
                <a
                    style={combinedStyles}
                    href={href}
                    className={classes}
                    target={isExternal ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                >
                    <EditableText classes="" handleChange={handleChange} data={data.typography}>
                        {children}
                    </EditableText>
                </a>
                :
                <Link
                    style={combinedStyles}
                    to={href}
                    className={classes}
                >
                    <EditableText classes="" handleChange={handleChange} data={data.typography}>
                        {children}
                    </EditableText>
                </Link>
        )
    );
}